import React, { useState, useEffect, useMemo, Fragment, useRef, forwardRef, useImperativeHandle } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@mui/material'
import DaumPostcodeEmbed, { useDaumPostcodePopup } from 'react-daum-postcode'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Agmt from '@components/Agmt'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import Labeller from '@components/Labeller'
import { alignProperty } from '@mui/material/styles/cssUtils'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/ko'
import { useSelector, useDispatch } from 'react-redux'
import MinwonInput from '@components/MinwonInput'
import AgmtOffice from '@components/AgmtOffice'
import { Form, useParams } from 'react-router-dom'
import NumericTextField from '@components/NumericTextField'
import { take, takeRight, drop } from 'lodash'
import AttachFileOutlined from '@mui/icons-material/AttachFileOutlined'
import moment from 'moment'
import { MAX_FILE_SIZE } from '@util/constant'
import { billiard, attrGuide } from '@util/string'
import SignatureCanvas from 'react-signature-canvas'
import { useLocation } from 'react-router-dom'
import BuildingGuide from './BuildingGuide'
import {comma, getAddress} from '@src/util/helper'
import useAmount from '@hooks/useAmount'
const alignCenter = {
  display: 'flex',
  alignItems: 'center',
}

const tsx = 3

const needAddr = ['DG01', 'DG02', 'DG03', 'DG04', 'DG05', 'DG06', 'DG07', 'DG08', 'DG11']
const needEffYn = ['DG01', 'DG02']
const needEst = ['DG01', 'DG11']
const RestPartEdit = ({ record, set_record, docCode }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const codes = useSelector((state) => state.codeReducer)
  const account = useSelector((state) => state.accountReducer)
  const dgRef = useRef()
  const recvRef = useRef()
  const location = useLocation()
  const curLocationRef = useRef(location)

  const aimCodes = codes.filter((r) => r.codeGroupId === 'D003')
  const bizCodes = codes.filter((r) => r.codeGroupId === 'D004')
  const factCodes = codes.filter((r) => r.codeGroupId === 'D005')
  const [file, set_file] = useState(null)
  const sigRef = useRef(null)
  const guideRef = useRef(null)
  const estCodes = codes.filter((r) => r.codeGroupId === 'D006')
  const corpCodes = codes.filter((r) => r.codeGroupId === 'D007')
  const carCodes = codes.filter((r) => r.codeGroupId === 'D008')

  const {price, amount} = useAmount(record)
  const onChangeFile = (e) => {
    const file = e.target.files[0]
    if (file.size > MAX_FILE_SIZE) {
      alert('파일 크기는 1MB 이하만 가능합니다.')
      return
    }
    record.file = file
    set_file(file)
  }

  useEffect(() => {
    console.log('account=>', account)
    // if(curLocationRef.current.key !== location.key) {
    //   curLocationRef.current = location
    //   console.log('location changed.....')
    // }
  }, [location])
  return (
    <>
      <AddrPopup
        ref={dgRef}
        title={'주소검색'}
        windowWidth='sm'
        callback={(data) => {
          const addr = getAddress(data, record.hanYn === 'Y', true)
          console.log('set addr =>', addr)
          set_record({ ...record, addr1: addr })
        }}
      />
      <RecvPopup ref={recvRef} title={'수령방법'} windowWidth='sm' callback={(recv) => {}} />
      <BuildingGuide ref={guideRef} title={'대장구분/종류'} windowWidth='sm' />

      <Container maxWidth='lg'>
        <Grid container spacing={1}>
          
          {
            docCode === 'DG08' ? (
              <Grid item xs={12}>
                <Typography color={theme.palette.secondary.main} style={{ margin: 5 }}>
                  {billiard} 주택가격확인서 발급시 확인서에 표기되는 신청인은 당사 또는 당사 직원명의로 표기됩니다. 원하지 않을경우 민원신청을 하지마시고 신청인 타인 표기를 이유로 환불 불가합니다.
                </Typography>
              </Grid>
            ): null
          }
          <MinwonInput
            label='신청인성명'
            component={
              <TextField fullWidth disabled={account?.user?.id } 
                defaultValue={record.name}
                onChange={e => {
                  record.name = e.target.value
                }}
              />
            }
          />
          <MinwonInput
            label='전화번호'
            must={true}
            component={
              <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={3}>
                  <TextField fullWidth value={record.phoneDial} disabled={true} />
                </Grid>
                <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography textAlign={'center'}>-</Typography>
                </Grid>
                <Grid item xs={3}>
                  <NumericTextField
                    fullWidth
                    maxLength={4}
                    value={record.phone1}
                    onChange={(e) => {
                      set_record({ ...record, phone1: e })
                    }}
                  />
                </Grid>
                <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography textAlign={'center'}>-</Typography>
                </Grid>
                <Grid item xs>
                  <NumericTextField
                    fullWidth
                    maxLength={4}
                    value={record.phone2}
                    onChange={(e) => {
                      set_record({ ...record, phone2: e })
                    }}
                  />
                </Grid>
              </Grid>
            }
          />
          {docCode === 'DG02' ? (
            <>
              <MinwonInput
                label='법인구분'
                must={true}
                component={
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id='corpCode'>법인구분</InputLabel>
                        <Select
                          labelId='corpCode'
                          required
                          label='법인구분'
                          defaultValue={record.corpCode}
                          onChange={async (e) => {
                            record.corpCode = e.target.value
                          }}
                        >
                          {corpCodes.map((l, index) => {
                            return (
                              <MenuItem key={index} value={l.code}>
                                {l.name}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                }
              />

              <MinwonInput
                label='법인상호'
                must={true}
                component={
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        defaultValue={record.companyName}
                        onChange={(e) => {
                          record.companyName = e.target.value
                        }}
                      />
                    </Grid>
                  </Grid>
                }
              />
              <MinwonInput
                label='등기번호'
                component={
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        defaultValue={record.corpNum}
                        onChange={(e) => {
                          record.corpNum = e.target.value
                        }}
                      />
                    </Grid>
                  </Grid>
                }
              />
              <MinwonInput
                label='추가선택'
                component={
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel label='지점' control={<Checkbox color='primary' />} />
                      <FormControlLabel label='지배인/대리인' control={<Checkbox color='primary' />} />
                    </Grid>
                  </Grid>
                }
              />
            </>
          ) : null}

          {docCode === 'DG03' ? (
            <>
              <MinwonInput
                label='토지구분'
                must={true}
                component={
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <RadioGroup
                        row
                        defaultValue={record.dajiYn}
                        onChange={(e) => {
                          record.dajiYn = e.target.value
                        }}
                      >
                        {[
                          { code: 'N', name: '토지(임야)대장' },
                          { code: 'Y', name: '토지(임야)대장 대지권등록부' },
                        ].map((code, idx) => (
                          <FormControlLabel key={idx} value={code.code} label={code.name} control={<Radio />} />
                        ))}
                      </RadioGroup>
                    </Grid>
                  </Grid>
                }
              />
              <MinwonInput
                label='연혁유무'
                must={true}
                component={
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <RadioGroup
                        row
                        defaultValue={record.historyYn}
                        onChange={(e) => {
                          record.historyYn = e.target.value
                        }}
                      >
                        {[
                          { code: 'Y', name: '유' },
                          { code: 'N', name: '무' },
                        ].map((code, idx) => (
                          <FormControlLabel key={idx} value={code.code} label={code.name} control={<Radio />} />
                        ))}
                      </RadioGroup>
                    </Grid>
                  </Grid>
                }
              />
            </>
          ) : null}

          {docCode === 'DG04' ? (
            <>
              <MinwonInput
                label='건물구분'
                must={true}
                component={
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <RadioGroup
                        row
                        defaultValue={record.bldgOneYn}
                        onChange={(e) => {
                          record.bldgOneYn = e.target.value
                        }}
                      >
                        {[
                          { code: 'Y', name: '일반(단독주택)' },
                          { code: 'N', name: '집합건물(아파트,연립주택등)' },
                        ].map((code, idx) => (
                          <FormControlLabel key={idx} value={code.code} label={code.name} control={<Radio />} />
                        ))}
                      </RadioGroup>
                    </Grid>
                    <Grid item xs md>
                      <Button onClick={() => guideRef.current.open()}>대장구분/종류안내</Button>
                    </Grid>
                  </Grid>
                }
              />
              <MinwonInput
                label='건물종류'
                must={true}
                component={
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <RadioGroup
                        row
                        defaultValue={record.bldgChongYn}
                        onChange={(e) => {
                          record.bldgChongYn = e.target.value
                        }}
                      >
                        {[
                          { code: 'Y', name: '총괄' },
                          { code: 'N', name: '일반' },
                        ].map((code, idx) => (
                          <FormControlLabel key={idx} value={code.code} label={code.name} control={<Radio />} />
                        ))}
                      </RadioGroup>

                    </Grid>
                  </Grid>
                }
              />
            </>
          ) : null}

          {docCode === 'DG08' ? (
            <MinwonInput
              label='확인서구분'
              must={true}
              component={
                <>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <RadioGroup
                        row
                        defaultValue={record.houseOneYn}
                        onChange={(e) => {
                          record.houseOneYn= e.target.value
                        }}
                      >
                        {[
                          { code: 'Y', name: '개별주택가격확인서' },
                          { code: 'N', name: '공동주택가격확인서' },
                        ].map((code, idx) => (
                          <FormControlLabel key={idx} value={code.code} label={code.name} control={<Radio />} />
                        ))}
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </>
              }
            />
          ) : null}

          {needEst.includes(docCode) ? (
            <MinwonInput
              label='부동산구분'
              must={true}
              component={
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id='estCode'>구분</InputLabel>
                      <Select
                        labelId='estCode'
                        required
                        label='구분'
                        name={'eastCode'}
                        value={record.estCode}
                        defaultValue={''}
                        onChange={async (e) => {
                          set_record({...record, estCode: e.target.value})
                        }}
                      >
                        {estCodes.map((l, index) => {
                          return (
                            <MenuItem key={index} value={l.code}>
                              {l.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs></Grid>
                </Grid>
              }
            />
          ) : null}
          {needAddr.includes(docCode) ? (
            <MinwonInput
              label='소재지'
              must={true}
              component={
                <Grid container spacing={1} style={gs.alignCenter}>
                  <Grid item xs={9} md={6}>
                    <TextField fullWidth label='기본주소' value={record.addr1} disabled={true} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <TextField fullWidth label='상세주소' 
                      defaultValue={record.addr2}
                      onChange={(e) => {
                        record.addr2 = e.target.value
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md>
                    <Button
                      fullWidth
                      size='middle'
                      onClick={() => {
                        dgRef.current.open()
                      }}
                    >
                      주소찾기
                    </Button>
                  </Grid>
                </Grid>
              }
            />
          ) : null}

          {needEffYn.includes(docCode) ? (
            <MinwonInput
              label='등기유형'
              must={true}
              component={
                <Grid container spacing={1} style={{ alignItems: 'center' }}>
                  <Grid item xs={12}>
                    <RadioGroup
                      row
                      defaultValue={record.effYn}
                      onChange={(e) => {
                        record.effYn = e.target.value
                      }}
                    >
                      {[
                        { code: 'Y', name: '현재유효사항' },
                        { code: 'N', name: '말소사항포함' },
                      ].map((code, idx) => (
                        <FormControlLabel key={idx} value={code.code} label={code.name} control={<Radio />} />
                      ))}
                    </RadioGroup>
                  </Grid>
                </Grid>
              }
            />
          ) : null}

          {docCode === 'DG09' ? (
            <>
              <MinwonInput
                label='차량등록번호'
                must={true}
                component={
                  <Grid container spacing={1} style={{ alignItems: 'center' }}>
                    <Grid item xs={12} md={6}>
                      <TextField fullWidth
                        defaultValue={record.carNum}
                        onChange={e => {
                          record.carNum = e.target.value
                        }}
                      />
                    </Grid>
                  </Grid>
                }
              />

              <MinwonInput
                label='최종소유주'
                must={true}
                component={
                  <Grid container spacing={1} style={{ alignItems: 'center' }}>
                    <Grid item xs={12} md={6}>
                      <TextField fullWidth
                        defaultValue={record.lastOwner}
                        onChange={e => {
                          record.lastOwner= e.target.value
                        }}
                      />
                    </Grid>
                  </Grid>
                }
              />
              <MinwonInput
                label='권리구분'
                must={true}
                component={
                  <Grid container spacing={1} style={{ alignItems: 'center' }}>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel id='carCode'>권리구분</InputLabel>
                        <Select
                          labelId='carCode'
                          required
                          label='권리구분'
                          defaultValue={''}
                          onChange={async (e) => {
                            record.carCode = e.target.value
                          }}
                        >
                          {carCodes.map((l, index) => {
                            return (
                              <MenuItem key={index} value={l.code}>
                                {l.name}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                }
              />
            </>
          ) : null}
          <MinwonInput
            label='열람용/제출용'
            must={true}
            component={
              <Grid container spacing={1} style={{ alignItems: 'center' }}>
                <Grid item xs={12}>
                  <RadioGroup
                    row
                    value={record.giveYn}
                    onChange={(e) => {
                      // record.giveYn = e.target.value
                      set_record({...record, giveYn: e.target.value}) 
                    }}
                  >
                    {[
                      { code: 'N', name: '열람용' },
                      { code: 'Y', name: '제출용' },
                    ].map((code, idx) => (
                        docCode === 'DG08' && code.code === 'N' ?  null : (
                          <FormControlLabel key={idx} value={code.code} label={code.name} control={<Radio />} />
                        )
                    ))}
                  </RadioGroup>
                </Grid>
              </Grid>
            }
          />
          <MinwonInput label='수량/가격' must={true} 
            component={
              <Grid container spacing ={1} style={gs.alignCenter}>
                <Grid item xs={6} md={2}>
                  <Typography>수량</Typography>
                </Grid> 
                <Grid item xs={6} md={4}>
                  <NumericTextField
                    style={{paddingRight:100}}
                    fullWidth
                    maxLength={3}
                    value={record.qty}
                    onChange={(e) => {
                      set_record({ ...record, qty: e })
                    }}
                  />

                </Grid> 
                <Grid item xs={6} md={2}>
                  <Typography>금액</Typography>
                </Grid> 
                <Grid item xs={6} md={4}>
                  <Typography>{comma(amount)}</Typography>
                </Grid> 
              </Grid>
            }
          />
        </Grid>
      </Container>
    </>
  )
}

export default RestPartEdit
