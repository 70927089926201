import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Link, useNavigate } from 'react-router-dom'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import TextField from '@mui/material/TextField'
// redux
import { useSelector, useDispatch } from 'react-redux'
import WebService from '@util/webService'
import { emailRegex, getAction, printVariable } from '@util/helper'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import kbank from '@images/kbank.png'
import '@scss/App.scss'
import { isAdmin } from '@util/helper'
import {
  Avatar,
  CardHeader,
  Container,
  FormHelperText,
  IconButton,
  Divider,
  Grow,
  Slide,
  Collapse,
  Zoom,
} from '@mui/material'
import { TransitionGroup } from 'react-transition-group'
import ConfirmDialog from '@components/ConfirmDialog'
import useIndicator from '@hooks/useIndicator'
import useAlert from '@hooks/useAlert'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import { setActiveSideIndex } from '@redux/actions/activeSideIndexAction'
import { companyPhone } from '@util/string'
import { showSnackbar } from '@context/SnackbarContext'
import { setCodes } from '@redux/actions/codeAction'
import { genMenu } from '@util/helper'
import ArticleOutlined from '@mui/icons-material/ArticleOutlined'
import { prss } from '@util/string'
import {
  SavingsOutlined,
  DirectionsBoatFilledOutlined,
  AccountBalanceOutlined,
  ReceiptOutlined,
  FactCheckOutlined,
  ReceiptLongOutlined,
  CorporateFareOutlined,
  SpaOutlined,
  DirectionsCarFilledOutlined,
  CropLandscapeOutlined,
  BorderInnerOutlined,
  BusinessOutlined,
  LanguageOutlined,
  HomeWorkOutlined,
  AddRoadOutlined,
  ZoomOutMapOutlined,
  AssessmentOutlined,
  OtherHousesOutlined,
  HourglassTopOutlined,
} from '@mui/icons-material'
import consoleLogger from 'react-terminal-logger/console-logger'
import { add } from 'lodash'
import { BASE_URL } from '@util/constant'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import Carousel from 'react-elastic-carousel'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import QueryBuilderOutlined from '@mui/icons-material/QueryBuilderOutlined';
const axiosInstance = axios.create({
  baseURL: BASE_URL,
})

axiosInstance.interceptors.request.use(
  async (config) => {
    return config
  },
  (error) => {
    console.log('axios error while intercepting.... error ===>', error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.log('error=>', error)
    const originalRequest = error.config
    if (error.code === 'ERR_NETWORK') {
      sysAlert('에러가 발생했습니다.')
      return
    } else {
      setLoading(false)
      sysAlert(error.message)
      return
    }
  }
)

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  padding: theme.spacing(1),
  textAlign: 'center',
  borderBottom: '1 solid #ccc',
  boxShadow: {},
  color: theme.palette.text.secondary,
}))

const FormHelperTexts = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const CardFace = ({ title }) => {
  const theme = useTheme()
  return (
    <CardContent
      style={{
        backgroundColor: gs.colors.cardBg,
        display: 'flex',
        justifyContent: 'center',
        borderBottom: '1px solid silver',
      }}
    >
      <Typography color='#616161' style={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
    </CardContent>
  )
}

const CircleButton = ({ menuId, text, onClick }) => {
  const theme = useTheme()

  const iconStyle = {
    fontSize: 40,
    borderWidth: 1,
    color: theme.palette.secondary.light,
  }

  const icons = {
    DS01: <SavingsOutlined style={iconStyle} />,
    DS02: <ReceiptOutlined style={iconStyle} />,
    DS03: <DirectionsBoatFilledOutlined style={iconStyle} />,
    DS04: <AccountBalanceOutlined style={iconStyle} />,
    DS05: <FactCheckOutlined style={iconStyle} />,
    DS06: <ReceiptLongOutlined style={iconStyle} />,
    DS07: <CorporateFareOutlined style={iconStyle} />,
    DS08: <SpaOutlined style={iconStyle} />,
    DS09: <ArticleOutlined style={iconStyle} />,
    DS10: <ReceiptLongOutlined style={iconStyle} />,
    DS11: <FactCheckOutlined style={iconStyle} />,
    DG01: <CropLandscapeOutlined style={iconStyle} />,
    DG11: <BorderInnerOutlined style={iconStyle} />,
    DG02: <BusinessOutlined style={iconStyle} />,
    DG03: <LanguageOutlined style={iconStyle} />,
    DG04: <HomeWorkOutlined style={iconStyle} />,
    DG05: <AddRoadOutlined style={iconStyle} />,
    DG06: <ZoomOutMapOutlined style={iconStyle} />,
    DG07: <AssessmentOutlined style={iconStyle} />,
    DG08: <OtherHousesOutlined style={iconStyle} />,
    DG09: <DirectionsCarFilledOutlined style={iconStyle} />,
  }

  const MenuIcon = () => {
    return icons[menuId] ?? <ArticleOutlined style={iconStyle} />
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Button
        onClick={onClick}
        variant='outlined'
        color='primary'
        startIcon={
          <MenuIcon menuId={menuId} />
          // <ArticleOutlined
          //   style={{
          //     fontSize: 40,
          //     borderWidth: 1,
          //     color: theme.palette.secondary.light,
          //   }}
          // />
        }
        sx={{
          borderRadius: '50%',
          width: 65,
          height: 65,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
          '& .MuiButton-startIcon': {
            margin: 'auto', // Center the start icon horizontally and vertically
          },
          borderWidth: 3,
          '&:hover': {
            borderWidth: 3, // Border width when focused
          },
        }}
      />
      <Typography style={{ fontSize: '0.9em' }}>{text}</Typography>
    </div>
  )
}

const WelcomeIndexPage = () => {
  const [tabValue, set_tabValue] = useState('1')

  const codeGroups = useSelector((state) => state.codeGroupReducer)
  const codes = useSelector((state) => state.codeReducer)
  const account = useSelector((state) => state.accountReducer)
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useDispatch()
  const handleTabChange = (event, newValue) => {
    set_tabValue(newValue)
  }
  const [containerKey, set_containerKey] = useState(0)
  const [semuMenu, set_semuMenu] = useState([])
  const [restMenu, set_restMenu] = useState([])
  const [orders, set_orders] = useState([])
  const docCodes = codes.filter((r) => r.codeGroupId === 'D001')
  const statCodes = codes.filter((r) => r.codeGroupId === 'C002')
  const [fetching, set_fetching] = useState(false)
  const containerRef = useRef(null)
  const location = useLocation()
  const intervalRef = useRef(null)
  const carouselRef = useRef(null)
  let resetTimeout
  const fetchCodes = async () => {
    const url = '/code'
    const resp = await WebService.get(url)
    if (resp.repCode != 'ack') {
      console.log('error=>', resp.repMessage)
    }
    const repMessage = JSON.parse(resp.repMessage)
    const records = JSON.parse(repMessage.records)
    
    records.forEach((item) => {
      if (item.code === 'DG08') {
        item.prss1 = prss.am
        item.prss2 = prss.pm
      } else if (item.code === 'DG11') {
        item.prss1 = prss.am
        item.prss2 = prss.pm
      } else {
        if (item.code.startsWith('DS')) {
          item.prss1 = prss.am
          item.prss2 = prss.pm
        } else {
          item.prss1 = prss.short
          item.prss2 = prss.short
        }
      }
    })
    

    /*
    records.forEach((item) => {
      if (item.code === 'DG08') {
        item.prss1 = prss.long
        item.prss2 = prss.pm
      } else if (item.code === 'DG11') {
        item.prss1 = prss.am
        item.prss2 = prss.pm
      } else {
        if (item.code.startsWith('DS')) {
          item.prss1 = prss.am
          item.prss2 = prss.pm
        } else {
          item.prss1 = prss.short
          item.prss2 = prss.pm
        }
      }
    })
    */
    return records
  }

  const fetchOrders = async () => {
    const url = `/public/order`
    const params = {
      pageNum: 0,
      pageSize: 10,
    }
    const resp = await axiosInstance.get(url, {params: params})
    if (resp.data.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }
    const repMessage = JSON.parse(resp.data.repMessage)
    const records = JSON.parse(repMessage.records)
    // console.log('records=>', records)
    return records.take(10)
  }

  const maskString = (str) => {
    const firstString = str.charAt(0)
    const restString = str.substring(1).replace(/./g, '⛌')
    return firstString + restString.take(10)
  }

  const onLoad = async () => {
    const codes = await fetchCodes()
    if (codes) {
      dispatch(setCodes(codes))
    }

    const orders = await fetchOrders()
    if (orders) {
      set_orders(orders)
    }
  }

  useEffect(() => {
    if (account && account?.user?.emailCertYn === 'N') {
      console.log('navigate account=>', account)
      navigate('/welcome/ecert')
    }
  }, [account])

  useEffect(() => {
    const semuMenu = genMenu(codes, 'D001', 'DS', `/ask/semu`)
    const restMenu = genMenu(codes, 'D001', 'DG', `/ask/rest`)
    set_semuMenu(semuMenu)
    set_restMenu(restMenu)
  }, [codes])

  useEffect(() => {
    onLoad()
  }, [])

  useEffect(() => {
    console.log('location=>', location.pathname)
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }

   
    /*
    intervalRef.current = 
      setInterval(async () => {
        set_fetching(true)
        const newOrders = await fetchOrders()
        set_orders(newOrders)
        set_fetching(false)
      }, 5000)

    return () => {
      console.log('cleanup_interval')
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
    */ 
  }, [location])

  return (
    <>
      <div style={{ backgroundColor: '#eee', paddingTop: isMobile ? 50 : 100, paddingBottom: 50 }}>
        <Container maxWidth='lg' style={{ display: isMobile && tabValue === '2' ? 'none' : null }}>
          <Grid container spacing={2} style={{}}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {semuMenu.map((item, idx) => (
                  <Grid item xs={3} md={2} key={idx}>
                    <CircleButton
                      menuId={item.menuId}
                      text={item.label}
                      onClick={() => {
                        dispatch(setActiveSideIndex(item.id))
                        navigate(item.to)
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {restMenu.map((item, idx) => (
                  <Grid item xs={3} md={2} key={idx}>
                    <CircleButton
                      menuId={item.menuId}
                      text={item.label}
                      onClick={() => {
                        dispatch(setActiveSideIndex(item.id))
                        navigate(item.to)
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container maxWidth={'lg'} style={{ marginTop: 25 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            {/* <Card> */}
            {/*   <CardFace title='민원 처리결과' /> */}
            {/*   <CardContent> */}
            {/*     <TextField fullWidth label='신청인 성명'></TextField> */}
            {/*     <TextField fullWidth label='비밀번호' style={{ marginTop: 10 }}></TextField> */}
            {/*     <Button fullWidth variant='contained' size='large' style={{ marginTop: 10 }}> */}
            {/*       확인 */}
            {/*     </Button> */}
            {/*   </CardContent> */}
            {/* </Card> */}
            {/* <Card style={{ marginTop: 10 }}> */}
            {/*   <CardFace title='업무시간' /> */}
            {/*   <CardContent> */}
            {/*     <Typography>요금 및 처리시간</Typography> */}
            {/*   </CardContent> */}
            {/* </Card> */}
            <Card style={{ marginTop: 10 }}>
              <CardFace title='무통장입금 계좌안내' />
              <CardContent>
                <Grid container spacing={1} style={{display:'flex', alignItems:'center'}}>
                  <Grid item xs={4} >
                    <img src={kbank} alt='kbank' style={{ width: isMobile ? 60 : 60, marginRight: 10, }} />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>예금주: 손미희</Typography>
                    <Typography>케이뱅크</Typography>
                    <Typography>100-210-735873</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card style={{ marginTop: 10 }}>
              <CardFace title='대량주문 안내' />
              <CardContent>
                <Typography variant='body2'>
                  대량주문은 e-mail로 따로 문의주세요(대량주문은 할인됩니다.)
                </Typography>
                <Typography variant='body2'>
                  e-minwon@naver.com
                </Typography>
              </CardContent>
            </Card>
            <Card style={{ marginTop: 10 }}>
              <CardFace title='배송 및 처리시간' />
              <CardContent>
                <div style={{display:'flex', cursor:'pointer'}}
                  onClick={() => {
                    dispatch(setActiveSideIndex(1))
                    navigate('/customer/process')
                  }}
                >
                  <QueryBuilderOutlined />
                  <Typography style={{marginLeft:10}}>배송 및 처리시간 안내</Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: 'bold', marginRight: 10 }}>실시간 신청 내역</Typography>
                {fetching ? (
                  <HourglassTopOutlined style={{ fontSize: '1em', color: theme.palette.primary.main }} />
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} md className='slider-container'>
                <Slider
                  vertical={true}
                  autoplay={true}
                  dots={false}
                  infinite={true}
                  speed={1000}
                  autoplaySpeed={1000}
                  slidesToShow={10}
                  slidesToScroll={1}
                >

                    {orders.map((item, idx) => (
                     //  <div key={idx}>
                     //    <div style={{display:'flex', flexDirection:'row', }}>
                     //     <div >{item.name} </div>
                     //     <div >{item.orderNo} </div>
                     //  </div>
                     // </div> 
                   
                    <div key={idx}>
                      <Grid container spacing={1} >
                        <Grid item xs={12} md={3} style={{display:'flex', }}>
                          {
                            isMobile ? (
                              <Typography variant='body2' style={{fontWeight:'bold', marginRight:10}} >
                                주문번호: 
                              </Typography>
                            ) : null
                          }
                          <Typography variant='body2' style={{fontWeight:'bold'}} >
                            {item.orderNo}
                          </Typography>
                        </Grid>
                        <Grid item xs md>
                          <Typography variant='body2'>
                            {/* {maskString(docCodes.find((r) => r.code === item.docs[0].docCode).name)} */}
                            {'⛌⛌⛌⛌⛌⛌'}
                          </Typography>
                        </Grid>
                        <Grid item xs md>
                          <Typography variant='body2'>
                            {maskString(item.name)}
                          </Typography>
                        </Grid>
                        <Grid item xs md={3}>
                          <Typography variant='body2'>
                            {statCodes.find((r) => r.code === item.docs[0].statCode).name}
                          </Typography>
                        </Grid>
                      </Grid>
                      {
                        isMobile ? (
                         <Divider /> 
                        ) : null
                      }
                    </div>

                    ))}
                </Slider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} >
            <Card style={{marginTop:10}}>
              <CardFace title='콜센터' />
              <CardContent>
                <Typography>전화 {companyPhone}</Typography>
                <Typography>평일 09:00 ~ 18:00</Typography>
                <Typography>공휴일 휴무</Typography>
              </CardContent>
            </Card>
            <Card style={{marginTop:10}}>
              <CardFace title='민원처리결과' />
              <CardContent>
                <div style={{display:'flex', cursor:'pointer'}}
                  onClick={() => {
                    if(account?.user?.id) {
                      dispatch(setActiveSideIndex(0))
                      navigate('/my')
                    }
                    else {
                      navigate('/login', {
                        state: {
                          pack:{
                            furl:'/my'
                          }
                        }
                      })
                    }
                  }}
                >
                  <ArticleOutlined />
                  <Typography style={{marginLeft:10}}>민원처리결과 확인</Typography>
                </div>

              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default WelcomeIndexPage
